import React, { useState } from 'react'
import './professions.less'
import { CareerDevelopmentCard } from './CareerDevelopmentCard'
import { Link } from 'gatsby'

interface CareerDevelopmentCard {
  profession: string
  headline: {
    headline: string
  }
  description: {
    description: string
  }
}

interface ProfessionsProps {
  title: string
  professions: string[]
  subline: string
  cards: CareerDevelopmentCard[]
  links: {
    url: string
    profession: {
      profession: string
    }
  }[]
}

export const Professions = ({ title, professions, subline, cards, links }: ProfessionsProps) => {
  const [profession, setProfession] = useState(professions[0].toLowerCase().trim())
  const [linkToDisplay, setLinkToDisplay] = useState(
    links.filter((link) => link.profession.profession.toLowerCase().trim() === profession),
  )

  const normalizedProfession = profession.toLowerCase().trim()

  const handleProfessionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setProfession(e.target.value.toLowerCase().trim())
    setLinkToDisplay(
      links.filter(
        (link) =>
          link.profession.profession.toLowerCase().trim() === e.target.value.toLowerCase().trim(),
      ),
    )
  }

  return (
    <div className="professions">
      <div className="Page-container">
        <div className="professions__wrapper">
          <span className="professions__title">{title}</span>
          <div>
            <div className="professions__content">
              <div className="professions__profession-select-wrapper">
                <p className="professions__content-text">Hej &nbsp;</p>
                <select
                  className="professions__content-select"
                  value={normalizedProfession}
                  onChange={handleProfessionChange}
                >
                  {professions.map((item, index) => (
                    <option
                      className="professions__content-option"
                      key={index}
                      value={item.toLowerCase().trim()}
                    >
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <p className="professions__subline">{subline}</p>
            </div>
            <Link to={linkToDisplay[0].url}>
              <div className="professions__feauters">
                {cards.map((item, index) => (
                  <CareerDevelopmentCard
                    key={`${index}-${item.profession}`}
                    isVisible={item.profession.toLowerCase().trim() === normalizedProfession}
                    title={item.headline.headline}
                    description={item.description.description}
                  />
                ))}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
