import classNames from 'classnames'
import { TextOnBackground } from 'components/TextOnBackground/TextOnBackground'
import Button from 'components/button/Button'
import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import './union-member-feature-section.less'

// Text theme is set to booelan in the CMS system, so the bussiness user can choose only between two options. For the bussiness (CMS User) user these options are displayed as blue and peach(oragne) text theme. Blue is represented as true and peach(oragne) is represented as false.
interface UnionMemberFeatureSectionProps {
  mobileBackgroundImage?: string
  desktopBackgroundImage?: string
  heading: string
  blockquote: string
  figcaption?: string
  variant: 'left' | 'right'
  buttonText?: string
  buttonHref?: string
  quoteUrl: string
  textTheme: boolean
  classNamePostfix?: string
}

export const UnionMemberFeatureSection: React.FC<UnionMemberFeatureSectionProps> = ({
  heading,
  blockquote,
  figcaption,
  buttonText,
  buttonHref,
  variant = 'left',
  mobileBackgroundImage,
  desktopBackgroundImage,
  quoteUrl,
  textTheme,
  classNamePostfix,
}) => {
  return (
    <div
      className={`union-member-feature-section ${
        variant === 'left' ? 'union-member-feature-section--left' : ''
      } ${variant === 'right' ? 'union-member-feature-section--right' : ''} ${
        mobileBackgroundImage ? `union-member-feature-section${classNamePostfix}--mobile` : ''
      } ${
        desktopBackgroundImage ? `union-member-feature-section${classNamePostfix}--desktop` : ''
      }`}
    >
      <style>
        {`
          .union-member-feature-section${classNamePostfix}--mobile {
            background-image: url(${mobileBackgroundImage});
          }
        
          @media (min-width: 1024px) {
            .union-member-feature-section${classNamePostfix}--desktop {
              background-image: url(${desktopBackgroundImage});
            }
          }
        `}
      </style>
      <div className="Page-container">
        <div
          className={classNames('union-member-feature-section__wrapper', {
            'union-member-feature-section__wrapper--left': variant === 'left',
            'union-member-feature-section__wrapper--right': variant === 'right',
          })}
        >
          <h2 className="union-member-feature-section__heading">{heading}</h2>
          <Link to={quoteUrl}>
            <TextOnBackground
              backgroundVariant={textTheme ? 'blue' : 'orange'}
              additionalTextStyles="union-member-feature-section__blockquote"
              additionalContainerStyles="union-member-feature-section__blockquote-container"
            >
              <blockquote>{blockquote}</blockquote>
            </TextOnBackground>
          </Link>
          {figcaption && (
            <figcaption className="union-member-feature-section__quote-source-container">
              {figcaption}
            </figcaption>
          )}
          {buttonText && buttonHref && (
            <Button
              href={buttonHref}
              className={classNames(
                'button button--rounded-new union-member-feature-section__cta-button',
                {
                  orange: !textTheme,
                  blue: textTheme,
                },
              )}
            >
              {buttonText}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
