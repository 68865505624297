import Button from 'components/button/Button'
import React from 'react'
import './events-and-webinars-section.less'
import WebinarItem from 'components/WebinarItem/WebinarItem'
import _uniqueId from 'lodash/uniqueId'

interface Event {
  date: string
  eventType: {
    eventType: string
  }
  title: {
    title: string
  }
  url: string
}

interface EventsAndWebinarsSectionProps {
  heading: string
  buttonLabel: string
  ctaHref: string
  events: Event[]
}

const EventsAndWebinarsSection: React.FC<EventsAndWebinarsSectionProps> = ({
  heading,
  buttonLabel,
  ctaHref,
  events,
}) => {
  return (
    <div className="events-and-webinars-section-wrapper">
      <div className="Page-container">
        <div className="events-and-webinars-section">
          <div className="events-and-webinars-section__header">
            <h2 className="events-and-webinars-section__heading">{heading}</h2>
          </div>
          <div className="events-and-webinars-section__featured">
            {events.map((event) => (
              <WebinarItem
                key={`${_uniqueId(event.url)}`}
                link={event.url}
                title={event.title.title}
                date={event.date}
                description={event.eventType.eventType}
              />
            ))}
          </div>
          <div className="events-and-webinars-section__cta-mobile-wrapper">
            <Button
              href={ctaHref}
              className="button button--outline button--rounded-new blue events-and-webinars-section__cta-mobile"
            >
              {buttonLabel}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventsAndWebinarsSection
