import React, { CSSProperties, ReactElement, useEffect, useState } from 'react'
import classNames from 'classnames'
import './TextOnBackground.less'

interface TextOnBackgroundProps {
  additionalTextStyles?: string
  additionalContainerStyles?: string
  children?: ReactElement | ReactElement[]
  backgroundVariant?: 'blue' | 'orange'
}
const useDeviceDetect = () => {
  const [isWindows, setIsWindows] = useState(false)
  const [isIos, setIsIos] = useState(false)
  const [isWindows11OrLater, setIsWindows11OrLater] = useState(false)

  useEffect(() => {
    if (navigator.userAgentData) {
      navigator.userAgentData.getHighEntropyValues(['platformVersion']).then((ua) => {
        if (navigator.userAgentData.platform === 'Windows') {
          setIsWindows(true)
          const majorPlatformVersion = parseInt(ua.platformVersion.split('.')[0])
          if (majorPlatformVersion >= 13) {
            setIsWindows11OrLater(true)
          }
        }
      })
    } else {
      const userAgent = window.navigator.userAgent
      if (/Windows/.test(userAgent)) {
        setIsWindows(true)
      } else if (/iPhone|iPad|iPod/.test(userAgent)) {
        setIsIos(true)
      }
    }
  }, [])

  return { isWindows, isIos, isWindows11OrLater }
}

export const TextOnBackground: React.FC<TextOnBackgroundProps> = ({
  additionalTextStyles,
  additionalContainerStyles,
  children,
  backgroundVariant = 'blue',
}) => {
  const { isWindows, isIos } = useDeviceDetect()

  const childrenWithStyles = React.Children.toArray(children).map((child, index) => {
    if (React.isValidElement(child) && index === 0) {
      const mergedStyles = classNames(
        'defaultStyles',
        child.props.className,
        additionalTextStyles,
        {
          'defaultStyles--blue': backgroundVariant === 'blue',
          'defaultStyles--orange': backgroundVariant === 'orange',
          'defaultStyles--windows': isWindows,
          'defaultStyles--ios': isIos,
        },
      )
      return React.cloneElement(child, { className: mergedStyles })
    }
    return child
  })

  const containerClass = classNames('containerStyles', additionalContainerStyles)

  return <div className={containerClass}>{childrenWithStyles}</div>
}
