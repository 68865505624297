import LatestNewsItem from 'components/LatestNewsItem/LatestNewsItem'
import Button from 'components/button/Button'
import React from 'react'
import { get } from 'lodash'

import './latest-news.less'

interface LatestNewsProps {
  heading: string
  buttonLabel: string
  ctaHref: string
  articles: Article[]
  allPages: any
}

interface Article {
  node: {
    id: string
    slug: string
    heading: string
    createdAt: string
    publishDate: string
    preamble: {
      preamble: string
    }
    featuredImage: {
      title: string
      description: string
      file: {
        url: string
      }
    }
  }
}

export const LatestNews = ({
  articles,
  buttonLabel,
  heading,
  ctaHref,
  allPages,
}: LatestNewsProps) => {
  return (
    <div className="latest-news-wrapper">
      <div className="Page-container">
        <div className="latest-news">
          <div className="latest-news__header">
            <h2 className="latest-news__heading">{heading}</h2>
          </div>
          <div className="latest-news__featured">
            {articles.map((item, index) => {
              let [{ path }] = allPages.filter(
                (link) => get(link, 'context.slug') == get(item.node, 'slug'),
              )

              return (
                <LatestNewsItem
                  key={index}
                  link={path}
                  imageSrc={item.node.featuredImage?.file.url}
                  heading={item.node.heading}
                  date={item.node.publishDate}
                  description={item.node.preamble?.preamble}
                />
              )
            })}
          </div>
          <div className="latest-news__cta-mobile-wrapper">
            <Button
              href={ctaHref}
              className="button button--outline button--rounded-new blue latest-news__cta-mobile"
            >
              {buttonLabel}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
