import { Link } from 'gatsby'
import React from 'react'
import './latest-news-item.less'
import classNames from 'classnames'

interface LatestNewsItemProps {
  link: string
  imageSrc: string
  heading: string
  date: string
  description?: string
  squareImage?: boolean
  smallHeading?: boolean
}

export const LatestNewsItem: React.FC<LatestNewsItemProps> = ({
  link,
  imageSrc,
  heading,
  date,
  description,
  squareImage,
  smallHeading,
}) => {
  const rawDate = new Date(date)

  // Format date to sweedish format of DD Month YYYY
  const options = { day: 'numeric', month: 'long', year: 'numeric', monthDisplay: 'long' }
  const formattedDate = rawDate.toLocaleDateString('sv-SE', options as Intl.DateTimeFormatOptions)

  return (
    <Link to={link} target="_blank" className="latest-news__link-wrapper">
      <div className="latest-news__featured-item">
        <div className="latest-news__featured-item-image-wrapper">
          {imageSrc &&
            <img
              className={classNames('latest-news__item-image', {
                'latest-news__item-image--square': squareImage,
              })}
              src={imageSrc}
              alt=""
            />
          }
        </div>
        <h4
          className={classNames('latest-news__item-heading', {
            'latest-news__item-heading--small': smallHeading,
          })}
          dangerouslySetInnerHTML={{ __html: heading }}
        />

        <p className="latest-news__item-date">{formattedDate}</p>
        {description && (
          <div
            className="latest-news__item-description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
    </Link>
  )
}

export default LatestNewsItem
