import { GatsbyImage } from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser'
import React from 'react'
import './offering-selection-item.less'
import { Link } from 'gatsby'

interface OfferingSelectionItemProps {
  imageUrl: string
  heading: string
  text: string
  href: string
}

const OfferingSelectionItem: React.FC<OfferingSelectionItemProps> = ({
  imageUrl,
  heading,
  text,
  href,
}) => {
  return (
    <Link to={href}>
      <div className="offering-selection-item">
        <div className="offering-selection-item__image-wrapper">
          <img className="offering-selection-item__image" src={imageUrl} />
        </div>
        <div className="offering-selection-item__text-wrapper">
          <h3 className="offering-selection-item__heading">{heading}</h3>
          <p className="offering-selection-item__text">{text}</p>
        </div>
      </div>
    </Link>
  )
}

export default OfferingSelectionItem
