import { Link } from 'gatsby'
import React from 'react'
import './webinar-item.less'

interface WebinarItemProps {
  link: string
  title: string
  date: string
  description: string
}

const WebinarItem: React.FC<WebinarItemProps> = ({ link, title, date, description }) => {
  const rawDate = new Date(date)

  const options = { day: 'numeric', month: 'long', monthDisplay: 'long' }
  let formattedDate = rawDate.toLocaleDateString('sv-SE', options as Intl.DateTimeFormatOptions)

  let [day, month] = formattedDate.split(' ')

  formattedDate = `${day} ${month}`

  return (
    <Link to={link}>
      <div className="events-and-webinars-section__featured-item">
        <div className="events-and-webinars-section__featured-item__header">
          <h4 className="events-and-webinars-section__featured-item__header__title">{title}</h4>
          <p className="events-and-webinars-section__featured-item__header__date">
            {formattedDate}
          </p>
        </div>
        <div className="events-and-webinars-section__featured-item__body">
          <p className="events-and-webinars-section__featured-item__body__description">
            {description}
          </p>
        </div>
      </div>
    </Link>
  )
}

export default WebinarItem
