import Button from 'components/button/Button'
import React from 'react'
import _uniqueId from 'lodash/uniqueId'
import './offering-selection.less'
import OfferingSelectionItem from './OfferingSelectionItem'

interface OfferingSelectionProps {
  heading: string
  buttonHref: string
  buttonText: string
  cards: any[]
}

export const OfferingSelection: React.FC<OfferingSelectionProps> = ({
  heading,
  buttonHref,
  buttonText,
  cards,
}) => {
  return (
    <div className="offering-wrapper">
      <div className="Page-container">
        <div className="offering-selection">
          <div className="offering-selection__heading-wrapper">
            <h2 className="offering-selection__heading">{heading}</h2>
          </div>

          <div className="offering-selection__grid">
            {cards.map((card) => (
              <OfferingSelectionItem
                key={_uniqueId('offering-card-')}
                href={card.cardUrl}
                imageUrl={card.cardImage.file.url}
                heading={card.cardHeadline.cardHeadline}
                text={card.cardDescription.cardDescription}
              />
            ))}
          </div>

          <div className="offering-selection__cta-mobile">
            <Button href={buttonHref} className="button button--rounded-new button--outline blue">
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
