import React from 'react'
import './career-development-card.less'
import classNames from 'classnames'

interface CareerDevelopmentCardProps {
  title: string
  description: string
  isVisible?: boolean
}

export const CareerDevelopmentCard = ({
  title,
  description,
  isVisible = false,
}: CareerDevelopmentCardProps) => {
  return (
    <div
      className={classNames('career-development-card', {
        'career-development-card--visible': isVisible,
      })}
    >
      <span className="career-development-card__title">{title}</span>
      <p className="career-development-card__description">{description}</p>
    </div>
  )
}
