import Button from 'components/button/Button'
import React from 'react'
import './salary-guide-section.less'

interface ISalaryGuideSectionProps {
  imageUrl: string
  imageAlt: string
  title: string
  heading: string
  text: string
  buttonHref: string
  buttonText: string
}

const SalaryGuideSection = ({
  imageUrl,
  imageAlt,
  title,
  heading,
  text,
  buttonHref,
  buttonText,
}: ISalaryGuideSectionProps) => {
  return (
    <div className="salary-guide-section-wrapper">
      <div className="Page-container">
        <div className="salary-guide-section">
          <div className="salary-guide-section__image-wrapper">
            <img className="salary-guide-section__image" src={imageUrl} alt={imageAlt} />
          </div>
          <div className="salary-guide-section__content-wrapper">
            <span className="salary-guide-section__title">{title}</span>
            <h1 className="salary-guide-section__heading ">{heading}</h1>
            <p className="salary-guide-section__text">{text}</p>
            <div>
              <Button href={buttonHref} className="button button--rounded-new orange">
                {buttonText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SalaryGuideSection
