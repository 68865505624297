import { StaticQuery, graphql } from 'gatsby'
const { compose, get, map } = require('lodash/fp')
import React from 'react'

import EventsAndWebinarsSection from 'components/EventsAndWebinarsSection/EventsAndWebinarsSection'
import { LatestNews } from 'components/LatestNews/LatestNews'
import {
  MagazinFeaturedSection,
  mapMagasinKAPIResponse,
} from 'components/MagazinFeaturedSection/MagazinFeaturedSection'
import { OfferingSelection } from 'components/OfferingSelection/OfferingSelection'
import { Professions } from 'components/ProfessionsAndIndustries/Professions'
import SalaryGuideSection from 'components/SalaryGuideSection/SalaryGuideSection'
import { UnionMemberFeatureSection } from 'components/UnionMemberFeatureSection/UnionMemberFeatureSection'
import { Hero } from 'components/hero/Hero'
import SEO from 'components/seo'
import LayoutNew from 'layout/LayoutNew'
import { connect } from 'react-redux'
import { ApplicationState } from 'store'
import { AuthState } from 'store/auth/types'
import { pushPageViewPreventDuplicates } from '../../utils/gtmUtils'

interface StateProps {
  auth: AuthState
}

interface Props extends StateProps {}

const query = graphql`
  query {
    allContentfulOpinionArticle(sort: { fields: publishDate, order: DESC }, limit: 2) {
      edges {
        node {
          id
          slug
          heading
          createdAt
          heading
          publishDate
          preamble {
            preamble
          }
          featuredImage {
            title
            description
            file {
              url
            }
          }
        }
      }
    }
    allSitePage {
      edges {
        node {
          path
          context {
            slug
          }
        }
      }
    }
    allContentfulStartPage {
      edges {
        node {
          metaTitle
          metaDescription {
            metaDescription
            id
          }
        }
      }
    }
    allContentfulNewHomePage {
      edges {
        node {
          magasinKSection {
            headline {
              headline
            }
            description {
              description
            }
            ctaButton {
              href
              label
            }
          }
          offeringSection {
            headline {
              headline
            }
            ctaButton {
              label
              href
            }
            offeringCards {
              ... on ContentfulNewOfferingSectionCard {
                cardHeadline {
                  cardHeadline
                }
                cardDescription {
                  cardDescription
                }
                cardUrl
                cardImage {
                  file {
                    url
                    fileName
                  }
                }
              }
            }
          }
          firstMembershipQuoteSection {
            content {
              textTheme
              mobileBackgroundImage {
                description
                file {
                  url
                }
              }
              desktopBackgroundImage: backgroundImage {
                description
                file {
                  url
                }
              }
              title {
                title
              }
              quote {
                quote
              }
              quoteUrl
              quoteAuthor {
                author {
                  author
                }
              }
              layout
              ctaButton {
                href
                label
              }
            }
          }
          secondMembershipQuoteSection {
            content {
              textTheme
              mobileBackgroundImage {
                description
                file {
                  url
                }
              }
              desktopBackgroundImage: backgroundImage {
                description
                file {
                  url
                }
              }
              title {
                title
              }
              quote {
                quote
              }
              quoteUrl
              quoteAuthor {
                author {
                  author
                }
              }
              layout
              ctaButton {
                label
                href
              }
            }
          }
          salaryAndStatistics {
            image {
              description
              file {
                url
              }
            }
            title {
              title
            }
            headline {
              headline
            }
            description {
              description
            }
            ctaButton {
              label
              href
            }
          }
          professionSelectionSection {
            title {
              title
            }
            orangeSubline {
              orangeSubline
            }
            professionLinks {
              ... on ContentfulNewProfessionSectionUrl {
                url
                profession {
                  profession
                }
              }
            }
            professionForSelection {
              ... on ContentfulNewProfession {
                profession {
                  profession
                }
              }
            }
            professionInfoCards {
              ... on ContentfulNewProfessionInformationCard {
                profession
                headline {
                  headline
                }
                description {
                  description
                }
              }
            }
          }
          eventsAndWebinarsSection {
            events {
              ... on ContentfulNewEventAndWebinarCard {
                date
                url
                title {
                  title
                }
                eventType {
                  eventType
                }
              }
            }
            headline {
              headline
            }
            ctaButton {
              label
              href
            }
          }
          latestNewsSection {
            headline {
              headline
            }
            ctaButton {
              label
              href
            }
          }
          heroSection {
            headline {
              headline
            }
            subline {
              subline
            }
            backgroundImageMobile {
              file {
                url
              }
            }
            backgroundImage {
              file {
                url
                fileName
              }
            }
            firstCallToActionButton {
              label
              href
            }
            secondCallToActionButton {
              label
              href
            }
          }
        }
      }
    }
    allMagasinKPost {
      totalCount
      edges {
        node {
          link
          date_gmt
          _embedded {
            wp_featuredmedia {
              source_url
            }
          }
          title {
            rendered
          }
          excerpt {
            rendered
          }
        }
      }
    }
  }
`

class IndexPage extends React.Component<Props> {
  componentDidMount() {
    pushPageViewPreventDuplicates()
  }

  render() {
    return (
      <StaticQuery
        query={query}
        render={(data) => {
          const allPages = compose(map('node'), get('allSitePage.edges'))(data)
          const startPage = compose(map('node'), get('allContentfulStartPage.edges'))(data)
          const [startPageContent] = startPage
          const dataNodes = data.allContentfulNewHomePage.edges[0].node

          return (
            <LayoutNew>
              <SEO
                description={startPageContent.metaDescription.metaDescription}
                title={startPageContent.metaTitle}
                keywords={[`gatsby`, `application`, `react`]}
              />
              <Hero
                headline={dataNodes.heroSection.headline.headline}
                subline={dataNodes.heroSection.subline.subline}
                desktopBackgroundImageUrl={dataNodes.heroSection.backgroundImage.file.url}
                mobileBackgroundImageUrl={dataNodes.heroSection.backgroundImageMobile.file.url}
                firstCtaButtonLabel={dataNodes.heroSection.firstCallToActionButton.label}
                secondCtaButtonLabel={dataNodes.heroSection.secondCallToActionButton.label}
                firstCtaButtonUrl={dataNodes.heroSection.firstCallToActionButton.href}
                secondCtaButtonUrl={dataNodes.heroSection.secondCallToActionButton.href}
              />
              <OfferingSelection
                cards={dataNodes.offeringSection.offeringCards}
                heading={dataNodes.offeringSection.headline.headline}
                buttonHref={dataNodes.offeringSection.ctaButton.href}
                buttonText={dataNodes.offeringSection.ctaButton.label}
              />
              <UnionMemberFeatureSection
                classNamePostfix="first"
                heading={dataNodes.firstMembershipQuoteSection.content.title.title}
                blockquote={dataNodes.firstMembershipQuoteSection.content.quote.quote}
                figcaption={
                  dataNodes.firstMembershipQuoteSection.content.quoteAuthor?.author?.author
                }
                variant={dataNodes.firstMembershipQuoteSection.content.layout ? 'left' : 'right'}
                desktopBackgroundImage={
                  dataNodes.firstMembershipQuoteSection.content.desktopBackgroundImage.file.url
                }
                mobileBackgroundImage={
                  dataNodes.firstMembershipQuoteSection.content.mobileBackgroundImage.file.url
                }
                buttonHref={dataNodes.firstMembershipQuoteSection.content.ctaButton?.href}
                buttonText={dataNodes.firstMembershipQuoteSection.content.ctaButton?.label}
                quoteUrl={dataNodes.firstMembershipQuoteSection.content.quoteUrl}
                textTheme={dataNodes.firstMembershipQuoteSection.content.textTheme}
              />
              <SalaryGuideSection
                imageUrl={dataNodes.salaryAndStatistics.image.file.url}
                imageAlt={dataNodes.salaryAndStatistics.image.description}
                title={dataNodes.salaryAndStatistics.title.title}
                text={dataNodes.salaryAndStatistics.description.description}
                heading={dataNodes.salaryAndStatistics.headline.headline}
                buttonHref={dataNodes.salaryAndStatistics.ctaButton.href}
                buttonText={dataNodes.salaryAndStatistics.ctaButton.label}
              />
              <Professions
                links={dataNodes.professionSelectionSection.professionLinks}
                title={dataNodes.professionSelectionSection.title.title}
                professions={dataNodes.professionSelectionSection.professionForSelection.map(
                  (item: any) => item.profession.profession,
                )}
                subline={dataNodes.professionSelectionSection.orangeSubline.orangeSubline}
                cards={dataNodes.professionSelectionSection.professionInfoCards}
              />
              <LatestNews
                articles={data.allContentfulOpinionArticle.edges}
                buttonLabel={dataNodes.latestNewsSection.ctaButton.label}
                ctaHref={dataNodes.latestNewsSection.ctaButton.href}
                heading={dataNodes.latestNewsSection.headline.headline}
                allPages={allPages}
              />
              <EventsAndWebinarsSection
                heading={dataNodes.eventsAndWebinarsSection.headline.headline}
                buttonLabel={dataNodes.eventsAndWebinarsSection.ctaButton.label}
                ctaHref={dataNodes.eventsAndWebinarsSection.ctaButton.href}
                events={dataNodes.eventsAndWebinarsSection.events}
              />
              <UnionMemberFeatureSection
                classNamePostfix="second"
                heading={dataNodes.secondMembershipQuoteSection.content.title.title}
                figcaption={
                  dataNodes.secondMembershipQuoteSection.content.quoteAuthor?.author?.author
                }
                blockquote={dataNodes.secondMembershipQuoteSection.content.quote.quote}
                variant={dataNodes.secondMembershipQuoteSection.content.layout ? 'left' : 'right'}
                buttonHref={dataNodes.secondMembershipQuoteSection.content.ctaButton?.href}
                buttonText={dataNodes.secondMembershipQuoteSection.content.ctaButton?.label}
                desktopBackgroundImage={
                  dataNodes.secondMembershipQuoteSection.content.desktopBackgroundImage.file.url
                }
                mobileBackgroundImage={
                  dataNodes.secondMembershipQuoteSection.content.mobileBackgroundImage.file.url
                }
                quoteUrl={dataNodes.secondMembershipQuoteSection.content.quoteUrl}
                textTheme={dataNodes.secondMembershipQuoteSection.content.textTheme}
              />
              <MagazinFeaturedSection
                buttonLabel={dataNodes.magasinKSection.ctaButton.label}
                ctaHref={dataNodes.magasinKSection.ctaButton.href}
                description={dataNodes.magasinKSection.description.description}
                heading={dataNodes.magasinKSection.headline.headline}
                latestArticles={mapMagasinKAPIResponse(data.allMagasinKPost)}
              />
            </LayoutNew>
          )
        }}
      />
    )
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return { auth: state.auth }
}

export default connect<StateProps, {}, {}, ApplicationState>(mapStateToProps)(IndexPage)
