import { TextOnBackground } from 'components/TextOnBackground/TextOnBackground'
import Button from 'components/button/Button'
import React from 'react'
import './hero.less'

interface HeroProps {
  headline: string
  subline: string
  firstCtaButtonLabel: string
  firstCtaButtonUrl: string
  secondCtaButtonLabel: string
  secondCtaButtonUrl: string
  desktopBackgroundImageUrl: string
  mobileBackgroundImageUrl?: string
}

export const Hero = ({
  firstCtaButtonLabel,
  headline,
  firstCtaButtonUrl,
  secondCtaButtonLabel,
  secondCtaButtonUrl,
  subline,
  desktopBackgroundImageUrl,
  mobileBackgroundImageUrl,
}: HeroProps) => {
  return (
    <div className="hero">
      <style>
        {`
          .hero {
            background-image: url(${mobileBackgroundImageUrl || desktopBackgroundImageUrl});
          }
        
          @media (min-width: 1024px) {
            .hero {
              background-image: url(${desktopBackgroundImageUrl});
            }
          }
        `}
      </style>
      <div className="Page-container wrapper">
        <div className="content">
          <TextOnBackground additionalContainerStyles="text-on-background ">
            <h1>{headline}</h1>
          </TextOnBackground>
          <p className="text-paragraph">{subline}</p>
          <div className="buttons">
            <Button href={firstCtaButtonUrl} className="button button--rounded-new orange">
              {firstCtaButtonLabel}
            </Button>
            <Button href={secondCtaButtonUrl} className="button button--rounded-new blue">
              {secondCtaButtonLabel}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
